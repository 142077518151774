import { memo, useState, useEffect, FormEvent, useRef } from 'react'

import { UiNodeInputAttributes } from '@ory/client'
import { getNodeId } from '@ory/integrations/ui'
import Box from 'lib/ui/Box'
import toast from 'react-hot-toast'

import useFormSubmit from '@/hooks/useFormSubmit'
import Messages from '@/ui/Messages'
import Node from '@/ui/Node'
import { isEmail, isPassword } from '@/ui/Node/utils'
import SkeltonLoader from '@/ui/SkeltonLoader'
import { emptyState, initializeNodes, filterNodes } from '@/utils/ory'
import { FormProps, FormValues } from '@/utils/ory/types'

const OryForm = <T extends FormValues>(props: FormProps<T>) => {
  const { flow, hideGlobalMessages, loadingProps, userEmail } = props

  const formRef = useRef<HTMLFormElement>(null)
  const [values, setValues] = useState(emptyState<T>())

  const { handleSubmit, isLoading } = useFormSubmit(props.onSubmit)

  const passwordConfirmName = 'password_confirm'

  const handleSubmitWithPasswordConfirmation = async (
    event: FormEvent<HTMLFormElement>
  ) => {
    event.stopPropagation()
    event.preventDefault()

    if (values['password'] !== values[passwordConfirmName]) {
      toast.error('Password confirmation does not match')
      return
    }

    handleSubmit(event)
  }

  useEffect(() => {
    initializeNodes(filterNodes(props))
  }, [props.flow])

  const nodes = filterNodes(props)

  if (!flow) {
    return (
      <SkeltonLoader
        divider={loadingProps?.divider}
        btnItems={loadingProps?.btnItems}
        formItems={loadingProps?.formItems}
      />
    )
  }

  return (
    <form
      action={flow?.ui.action}
      method={flow?.ui.method}
      onSubmit={handleSubmitWithPasswordConfirmation}
      ref={formRef}
    >
      {!hideGlobalMessages && (
        <Box padding={0} margin={[0, 0, 1]}>
          <Messages messages={flow?.ui.messages} />
        </Box>
      )}

      {nodes.map((node, k) => {
        const id = getNodeId(node) as keyof FormValues
        return (
          <div key={`${id}-${k}`}>
            <Box padding={0} margin={[0, 0, 1]}>
              <Node
                disabled={isLoading}
                node={node}
                value={
                  isEmail(node) && Boolean(userEmail) ? userEmail : values[id]
                }
                formRef={formRef}
                dispatchSubmit={handleSubmitWithPasswordConfirmation}
                setValue={async value =>
                  setValues(prevValues => ({
                    ...prevValues,
                    [getNodeId(node)]: value
                  }))
                }
              />
            </Box>
            {isPassword(node) && (
              <Box padding={0} margin={[0, 0, 1]}>
                <Node
                  disabled={isLoading}
                  node={{
                    ...node,
                    meta: {
                      ...node.meta,
                      label: {
                        ...node.meta.label,
                        text: 'Confirm new password'
                      }
                    },
                    attributes: {
                      ...node.attributes,
                      name: passwordConfirmName
                    } as UiNodeInputAttributes
                  }}
                  value={values[passwordConfirmName]}
                  setValue={async value =>
                    setValues(prevValues => ({
                      ...prevValues,
                      [passwordConfirmName]: value
                    }))
                  }
                />
              </Box>
            )}
          </div>
        )
      })}
    </form>
  )
}

export default memo(OryForm)

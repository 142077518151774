export const validations = {
  alpha: {
    test: /^[A-Za-z]+$/i,
    msg: 'Field must be only letters'
  },
  alphaNumeric: {
    test: /^[A-Za-z0-9]+$/i,
    msg: 'Field must be only letters or numbers'
  },
  alphaNumericSpecial: {
    test: /^[A-Za-z0-9'_./#&+-\s]+$/i,
    msg: 'No special characters allowed'
  },
  bankAccount: {
    test: /^\b(\d{6,17})\b/i,
    msg: 'Account must be 6-17 digits'
  },
  currency: {
    test: /^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)?$/,
    msg: 'Invalid amount, whole dollars only'
  },
  ein: {
    test: val => {
      if (val) {
        const isValid = /^\d{2}-?\d{7}$/.test(val)
        return isValid
      }
      return true
    },
    msg: 'EIN must be 9 digits'
  },
  email: {
    test: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    msg: 'Invalid email address'
  },
  lessThan100: {
    test: val => (!val ? true : val.length < 100),
    msg: 'Must be less than 100 characters'
  },
  numeric: {
    test: /^[0-9.]+$/i,
    msg: 'Must be a number'
  },
  routing: {
    test: /^\d{9}$/,
    msg: 'Routing number must be 9 digits'
  },
  domain: {
    test: val => {
      if (val) {
        const isValid =
          /^(?!:\/\/)([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(\/.*)?$/i.test(
            val
          )

        return isValid
      }
      return true
    },
    msg: 'Please enter a valid domain'
  },
  url: {
    test: val => {
      if (val) {
        const isValid =
          /((https?):\/\/)(www\.)?[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(
            val
          )
        return isValid
      }
      return true
    },
    msg: 'Please enter a valid URL'
  },
  zip: {
    test: val => {
      if (val) {
        const isValid = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(val)
        return isValid
      }
      return true
    },
    msg: 'Invalid zip code'
  },
  phone: {
    test: val => {
      if (val) {
        const isValid =
          /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(val)
        return isValid
      }
      return true
    },

    msg: 'Please enter a valid phone number'
  },
  ssn: {
    test: val => {
      if (!val) return true

      // ref: https://en.wikipedia.org/wiki/Social_Security_number#Valid_SSNs
      const regex = /^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/
      return regex.test(val)
    },
    msg: 'Format should be 123-45-6789'
  }
}

const USA_STATES = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Federated States of Micronesia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Marshall Islands',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Northern Mariana Islands',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Palau',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virgin Island',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
]

export const statesFull = USA_STATES.map(state => ({
  value: state,
  label: state
}))

const CA_PROVINCES = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Yukon'
]

export const provincesFull = CA_PROVINCES.map(p => ({ value: p, label: p }))
